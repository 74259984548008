/**
 * @name: 代理商管理-代理商信息管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 代理商管理-代理商信息管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import {IAgent, IAgentParams} from "@/apis/agent/list/types";
import { IDeviceArm } from '@/apis/device/arm-list/types';

export const agentListApi = (idOrName?: string) => api.get<IAgent[]>('/admin/device/getAgentList', {idOrName})

export const agentQueryApi = (params: IAgentParams) => api.get<IPageRes<IAgent[]>>('/admin/agent/query', params)

export const agentDetailApi = (id: string) => api.get<IAgent>('admin/agent/detail/' + id)

export const agentCreateApi = (data: Partial<IAgent>) => api.postJ('/admin/agent/create', data)

export const agentModifyApi = (data: Partial<IAgent>) => api.postJ('/admin/agent/modify', data)

export const agentModifyStatusApi = (id: string) => api.postJ('/admin/agent/updateStatus', {id})

export const agentDeviceListApi = (params: any) => api.get<IPageRes<IDeviceArm[]>>('/admin/agent/getDevicePage', params)

